<template>
  <div class="tfw-organisation-register mt-2">
    <CRow class="mx-0">
      <div class="content col-md-7 pb-3">
        <h2 class="text-white heading-1" v-if="step != 3">
          Organisation Registration
        </h2>
        <div v-if="step === 0">
          <label class="h4 text-white"
            >Are you a Registered TalentFind Customer?</label
          >
          <div class="radio-field mb-3">
            <RadioButton
              name="is_organisation"
              :value="userData.is_organisation"
              :options="boolean || []"
              @change="handleChangeRadio"
            />
          </div>
          <CRow class="mt-3">
            <CCol md="8" class="d-flex justify-content-between">
              <CButton
                name="step1-bt-home"
                color="primary"
                variant="outline"
                class="btn-tfw"
                to="/tfw"
              >
                <span>Home</span>
              </CButton>
              <CButton
                name="step1-bt-next"
                color="primary"
                class="btn-tfw"
                @click="changeStep('is_organisation', 1)"
              >
                <span>Next</span>
              </CButton>
            </CCol>
          </CRow>
        </div>
        <div v-else-if="step === 1">
          <div v-if="userData.is_organisation">
            <div v-if="!isOTPSent">
              <h4 class="text-white">
                Please Enter Your Username<span
                  class="ml-2"
                  v-c-tooltip="{
                    content: 'Please provide your Customer Admin Credentials',
                  }"
                  ><i class="fa-solid fa-circle-info"></i
                ></span>
              </h4>
              <div class="mt-3">
                <ValidationObserver
                  ref="formCredential"
                  v-slot="{ handleSubmit }"
                >
                  <form
                    id="formCredential"
                    @submit.prevent="handleSubmit(formCredential)"
                  >
                    <CRow class="mb-3">
                      <CCol md="12">
                        <label class="required text-white">Username</label>
                      </CCol>
                      <CCol md="8">
                        <ValidationProvider
                          rules="required|email"
                          v-slot="{ errors }"
                        >
                          <TextInput
                            name="username"
                            :value="userData.username"
                            @input="handleInput"
                            autocomplete="login-email"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </CCol>
                    </CRow>
                  </form>
                </ValidationObserver>
                <CRow class="mt-3">
                  <CCol md="8" class="d-flex justify-content-between">
                    <CButton
                      variant="outline"
                      color="primary"
                      name="step2-back"
                      class="btn-tfw"
                      @click="goToStep(0)"
                    >
                      <span>Back</span>
                    </CButton>
                    <CButton
                      name="step2-next"
                      color="primary"
                      class="btn-tfw"
                      @click="checkCredential"
                      :disabled="isLoading"
                    >
                      <span>Next</span>
                      <CSpinner
                        v-if="isLoading"
                        class="spinner-border-sm text-white m-1"
                      />
                    </CButton>
                  </CCol>
                </CRow>
              </div>
            </div>
            <div v-else>
              <h4 class="text-white">
                Please Input the Password as received in your Email<span
                  class="ml-2"
                  v-c-tooltip="{
                    content:
                      'Please check your Inbox and Spam folder for the OTP Email',
                  }"
                  ><i class="fa-solid fa-circle-info"></i
                ></span>
              </h4>
              <CRow class="mb-3">
                <CCol md="12">
                  <label class="required text-white">Password</label>
                </CCol>
                <CCol md="8">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <TextInput
                      name="password"
                      type="password"
                      :value="userData.password"
                      @input="handleInput"
                      autocomplete="login-password"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </CCol>
              </CRow>
              <CRow class="mt-3">
                <CCol md="8" class="d-flex justify-content-between">
                  <CButton
                    variant="outline"
                    color="primary"
                    name="step2-sub-back"
                    class="btn-tfw"
                    @click="isOTPSent = false"
                  >
                    <span>Back</span>
                  </CButton>
                  <CButton
                    name="step2-sub-next"
                    color="primary"
                    class="btn-tfw"
                    @click="verifyOTP"
                    :disabled="isLoading"
                  >
                    <span>Next</span>
                    <CSpinner
                      v-if="isLoading"
                      class="spinner-border-sm text-white m-1"
                    />
                  </CButton>
                </CCol>
              </CRow>
              <div class="mt-2">
                <span
                  v-if="countDown"
                  class="col-2 px-0 text-center text-white m-auto"
                  >You may request another OTP after {{ countDown }}
                  {{ `second${countDown > 1 ? "s" : ""}` }}</span
                >
                <button
                  v-else
                  type="button"
                  class="btn btn-link p-0 text-white"
                  v-c-tooltip="{ content: 'Click to Resend OTP' }"
                  @click="sendOTP"
                  :disabled="isLoading"
                >
                  Click here to Resend OTP
                </button>
              </div>
            </div>
            <div v-if="isAlreadyRegisteredOrganisation" class="mt-3">
              <h4 class="err-header">
                Your Organisation is already registered with <br />TalentFind
                World.
              </h4>
              <a
                :href="`/${isAlreadyRegisteredOrganisation}`"
                class="btn btn-link p-0 text-white"
                >Click here to login to your account</a
              >
            </div>
          </div>
          <div v-else class="jumbotron px-0 pt-4 bg-transparent text-white">
            <h5 class="text-capitalize" name="user_first_name">
              Please contact TalentFind Marketplace Administrator for Subscription
            </h5>
            <a
              href="mailto:admin@talentfindsolutions.com"
              class="text-white text-decoration-underline text-center h5"
              >admin@talentfindsolutions.com</a
            >
            <CRow class="mt-3">
              <CCol md="8" class="d-flex justify-content-between">
                <CButton
                  variant="outline"
                  color="primary"
                  name="step2-back"
                  class="btn-tfw"
                  @click="step = 0"
                >
                  <span>Back</span>
                </CButton>
                <CButton
                  color="primary"
                  name="step2-btn-home"
                  class="btn-tfw"
                  to="/tfw"
                >
                  <span>Home</span>
                </CButton>
              </CCol>
            </CRow>
          </div>
        </div>
        <div v-else-if="step === 2">
          <h4 class="text-white">
            Please confirm your Organisation from below:
          </h4>
          <RadioButton
            name="organisation_id"
            :value="userData.organisation_id"
            @change="handleChangeRadio"
            class="radio-field"
            :vertically="true"
            :options="registeredOrg || []"
          />
          <CRow class="mt-3">
            <CCol md="8" class="mb-3 d-flex justify-content-between">
              <CButton
                variant="outline"
                color="primary"
                name="step2-btn-cancel"
                class="btn-tfw"
                to="/tfw"
              >
                <span>Cancel</span>
              </CButton>
              <CButton
                color="primary"
                name="step2-btn-home"
                class="btn-tfw"
                @click="registerOrgInTFW"
                :disabled="isLoading"
              >
                <span>Submit</span>
                <CSpinner
                  v-if="isLoading"
                  class="spinner-border-sm text-white m-1"
                />
              </CButton>
            </CCol>
          </CRow>
        </div>
        <div v-else-if="step === 3">
          <h4 class="text-white heading-1 mb-3">Congratulations!</h4>
          <h5 class="text-white mb-3">
            Your Organisation is now successfully subscribed to TalentFind Marketplace
          </h5>
          <h5 class="text-white mb-3">
            Please login using your organisation URL
          </h5>
          <a
            class="text-white text-decoration-underline h5"
            :href="redirectURL.url"
            >{{ redirectURL.url }}</a
          >
          <h5 class="text-white mb-3 mt-3">
            Access TalentFind Marketplace menu and its features from your portal!
          </h5>
          <h5 class="text-white mb-3">
            Please contact
            <a
              class="text-white text-decoration-underline"
              href="mailto:admin@talentfindsolutions.com"
              >admin@talentfindsolutions.com</a
            >
            should you need any assistance
          </h5>
          <CRow class="mt-3">
            <CCol md="8" class="mb-3">
              <CButton
                color="primary"
                name="step2-btn-home"
                class="btn-tfw"
                to="/tfw"
              >
                <span>Home</span>
              </CButton>
            </CCol>
          </CRow>
        </div>
      </div>
    </CRow>
    <img
      src="/img/tfw/Doc--Nurse.png"
      sizes="(max-width: 479px) 80vw, (max-width: 991px) 70vw, 100vw"
      alt=""
      class="landing-image"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import TextInput from "@/components/reusable/Fields/TextInput";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import Vue from "vue";
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import { parseJwt } from "../../helpers/helper";
import { BASE_URL } from "@/service_urls";
extend("required", { ...required, message: "This field is required" });
extend("email", { ...email, message: "Invalid email" });

export default {
  name: "OrganisationRegister",
  components: {
    TextInput,
    RadioButton,
  },
  data() {
    return {
      userData: {},
      step: 0,
      isAlreadyRegisteredOrganisation: false,
      boolean: [
        { code: true, label: "Yes" },
        { code: false, label: "No" },
      ],
      isOTPSent: false,
      countDown: 180,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(["getOrgDetail"]),
    access_token() {
      const { query } = this.$route;
      const token = query?.token;
      if (token) return parseJwt(token);
      return {};
    },
    registeredOrg() {
      const { org_detail, name, location } = this.access_token;
      const loc = location || org_detail?.location;
      const location_content = loc ? `( ${loc} )` : "";
      return org_detail
        ? [
            {
              code: org_detail?.org_id,
              label: org_detail?.org_name,
              name,
              path_acronym: org_detail?.path_acronym,
              location: org_detail?.location_id,
              html_content: `<small>${location_content}<br>[Customer Admin - ${name}]`,
            },
          ]
        : [];
    },
    redirectURL() {
      let _baseUrl = BASE_URL;
      let _base_domain = window.location.origin;
      const { query } = this.$route;
      const path_acronym = query?.path_acronym || "";
      return { url: `${_base_domain}/${path_acronym}`, path_acronym };
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "checkOrgRelationship",
      "createCustomerOrgRelationship",
      "checkEmailOTP",
    ]),
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    handleInput(name, value) {
      Vue.set(this.userData, name, value);
    },
    handleChangeRadio(name, value) {
      Vue.set(this.userData, name, value?.code);
      if (name === "organisation_id" && value?.path_acronym)
        Vue.set(this.userData, "path_acronym", value?.path_acronym);
    },
    sendOTP() {
      this.isAlreadyRegisteredOrganisation = false;
      const {
        customer_uid: host_customer_uid,
        organisation_id: host_organisation_id,
      } = this.getOrgDetail;
      const { username: email, password } = this.userData;
      const payload = {
        host_customer_uid,
        host_organisation_id,
        email,
      };
      this.isOTPSent = false;
      this.isLoading = true;
      this.countDown = 180;
      this.checkOrgRelationship(payload).then((res) => {
        this.isLoading = false;
        if (res?.status === 200) {
          this.countDownTimer();
          this.isOTPSent = true;
        } else {
          if (res?.response?.status === 409) {
            this.isAlreadyRegisteredOrganisation =
              res.response.data.detail?.path_acronym || "web/login";
          }
        }
      });
    },
    verifyOTP() {
      if (!this.userData?.password) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please Enter valid OTP!",
        });
        return;
      }
      const { username, password } = this.userData;
      const payload = {
        username,
        password,
      };
      this.isLoading = true;

      this.checkEmailOTP(payload).then((res) => {
        this.isLoading = false;
        if (res?.status === 200) {
          this.step = 2;
          this.userData.organisation_id = null;
          this.updateQuery({ step: this.step, token: res.data.access_token });
        }
      });
    },
    goToStep(step) {
      this.step = step;
      if (step < 2) this.isOTPSent = false;
      this.updateQuery({ step: this.step });
    },
    changeStep(field, value) {
      if (this.userData[field] != undefined) {
        this.step = value;
        this.isAlreadyRegisteredOrganisation = false;
        if (value > 1) this.updateQuery({ step: this.step });
        return;
      }
      this.showToast({
        class: "bg-danger text-white",
        message: "Please fill out the fields!",
      });
    },
    async checkCredential() {
      const isValid = await this.$refs.formCredential.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please fill the user email!",
        });
        return;
      }
      this.sendOTP();
    },
    updateQuery(v) {
      let { query, path } = this.$route;
      this.$router.replace({ path, query: { ...query, ...v } });
    },
    registerOrgInTFW() {
      if (!this.userData?.organisation_id) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please choose your Organisation!",
        });
        return;
      }
      const {
        customer_uid: host_customer_uid,
        organisation_id: host_organisation_id,
      } = this.getOrgDetail;
      let { query } = this.$route;
      const access_token = query?.token || "";
      const path_acronym = this.userData?.path_acronym || "";
      const payload = {
        payload: {
          host_customer_uid,
          host_organisation_id,
        },
        access_token,
      };
      this.isLoading = true;
      this.createCustomerOrgRelationship(payload).then((res) => {
        this.isLoading = false;
        if (res?.status === 200) {
          this.step = 3;
          this.updateQuery({ step: this.step, path_acronym });
        }
      });
    },
  },
  mounted() {
    let { query } = this.$route;
    const step = parseInt(query?.step || 0);
    this.step = step > 1 ? step : 0;
  },
};
</script>
<style lang="scss" scoped>
.fs-16 {
  font-size: 16px !important;
}

.err-header {
  color: #ffffff;
  font-size: 18px;
}
</style>
